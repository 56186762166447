import React from "react";
import {
  SiteNavigationElementSchema,
  LocalBusinessSchema,
  RecaptchaProvider,
  TimedElement,
  FishermanModal,
  FishermanIcon,
  Announcement,
  ScrollElement,
  NavigationV2,
  PopupAccordionMenuLink,
  HookComponentProvider,
  Phone,
  LocationsContext,
  InternalLink,
  Image,
  SocialMedia,
  SiteMap,
  Subscribe,
  createContact,
  Location,
  Email,
  HoursOfOperation,
  CopyrightLabel,
  OutboundLink,
  PageTransition,
  FishermanBanner,
} from "@bluefin/components";
import { Button, Header, Grid, Popup, Icon, Segment } from "semantic-ui-react";
import { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  createImages,
  joinParentWithChildPages,
  filterRegularLinks,
  filterButtonLinks,
} from "../utils/utils";
import "semantic-ui-less/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            url
            pageType
            title
            navigationPriority
            groupName
            props
            _id
          }
        }
        fishermanBusiness {
          name
          logo
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          primaryLocation {
            timezone
            _id
          }
          _id
          type
          gatsbyLogo {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, height: 80)
            }
          }
          socialMedia {
            link
            type
          }
          brandIdentity
        }
        allFishermanBusinessCategory {
          nodes {
            category
            subCategory
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
            }
          }
        }
        announcementComponent: fishermanWebsiteComponent(
          layout: { name: { eq: "@" } }
          fastId: { eq: "Announcement" }
        ) {
          data {
            referenceId
            imagePosition
          }
          files {
            _id
            altText
            file
            gatsbyFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          contentNodes {
            _id
            content
            contentType
          }
          interactions {
            _id
            order
            behaviorType
            displayType
            enabled
            label
            style
            url
          }
        }
        allSitePage {
          nodes {
            path
            context {
              title
              fishermanParentPageId
              tagName
              fishermanParentPageType
              navigationOrder
              navigationGroup
              navigationGroupOrder
            }
          }
        }
        footerRegularPages: allFishermanBusinessWebsitePage(
          filter: { pageType: { ne: "Services" } }
        ) {
          nodes {
            _id
            title
            slug
            pageType
            url
            navigationPriority
            props
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessCategory,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
        announcementComponent,
        allSitePage,
        footerRegularPages,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.renewdayspanc.com"}
          />
          <LocalBusinessSchema
            name={fishermanBusiness.name}
            categories={allFishermanBusinessCategory.nodes}
            url={"https://www.renewdayspanc.com"}
            logo={fishermanBusiness.logo}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <React.Fragment>
              <RecaptchaProvider>
                <TimedElement
                  referenceId={announcementComponent.data.referenceId}
                  timezone={fishermanBusiness.primaryLocation.timezone}
                >
                  <TimedElement.Element>
                    <TimedElement.ActionComponent
                      passThroughActionProp={"onClose"}
                      action={"hide"}
                    >
                      <FishermanModal
                        className={"announcement-modal"}
                        dimmer={"inverted"}
                        defaultOpen={true}
                        closeIcon={
                          <Button icon={true}>
                            <FishermanIcon iconName={"close"} />
                          </Button>
                        }
                      >
                        <FishermanModal.Content>
                          <Announcement
                            images={createImages({
                              images: announcementComponent.files,
                            })}
                            contentNodes={announcementComponent.contentNodes}
                            interactions={announcementComponent.interactions}
                            imagePosition={
                              announcementComponent.data.imagePosition
                            }
                            businessId={fishermanBusiness._id}
                            businessType={fishermanBusiness.type}
                            locationId={fishermanBusiness.primaryLocation._id}
                          />
                        </FishermanModal.Content>
                      </FishermanModal>
                    </TimedElement.ActionComponent>
                  </TimedElement.Element>
                </TimedElement>
              </RecaptchaProvider>
              <ScrollElement
                scrollTarget={"#navigation"}
                targetPositionProperty={"bottom"}
                offset={300}
                className={"active"}
                applyOnlyToParent={true}
                scrollMode={"scrollPosition"}
                listenerNodeIds={["accordions-popup-menu-link"]}
              >
                <NavigationV2
                  fullScreen={true}
                  desktop={
                    <React.Fragment>
                      <NavigationV2.Container
                        containerId={"desktop-navigation-bar"}
                      >
                        <NavigationV2.Menu>
                          <NavigationV2.Logo
                            title={<Header>{fishermanBusiness.name}</Header>}
                            src={fishermanBusiness.gatsbyLogo.childImageSharp}
                          />
                        </NavigationV2.Menu>
                        <NavigationV2.Menu>
                          <NavigationV2.Links
                            links={joinParentWithChildPages({
                              pages: filterRegularLinks({
                                links: allFishermanBusinessWebsitePage.nodes,
                              }),
                              generatedPages: allSitePage.nodes,
                            })}
                            renderChildPages={
                              <PopupAccordionMenuLink
                                targetPositionId={"desktop-navigation-bar"}
                              />
                            }
                          />
                        </NavigationV2.Menu>
                        <NavigationV2.Menu>
                          <NavigationV2.Links
                            links={filterButtonLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            })}
                          />
                        </NavigationV2.Menu>
                      </NavigationV2.Container>
                    </React.Fragment>
                  }
                  mobile={
                    <React.Fragment>
                      <NavigationV2.Container className={"menu-header"}>
                        <NavigationV2.Toggle />
                        <div className={"actions-container"}>
                          <HookComponentProvider
                            hook={useContext}
                            component={
                              <Phone
                                iconName={"phone"}
                                event={{
                                  category: "Contact",
                                  action: "Call",
                                  label: "Navigation",
                                }}
                              />
                            }
                            hookToPropsMap={{
                              phone: "activeLocation.phoneNumber",
                            }}
                            hookParams={[LocationsContext]}
                            requiredProps={["phone"]}
                            onlyRenderOnClientSide={true}
                          />
                        </div>
                        <NavigationV2.Logo
                          title={<Header>{fishermanBusiness.name}</Header>}
                          src={fishermanBusiness.gatsbyLogo.childImageSharp}
                        />
                        <div className={"actions-container"}>
                          <Button
                            to={"/make-an-appointment/"}
                            as={InternalLink}
                            event={{
                              category: "Booking",
                              action: "Schedule Appointment Intent",
                            }}
                            content={"Book Now"}
                            aria-label={"Book an Appointment"}
                            primary={true}
                          />
                        </div>
                      </NavigationV2.Container>
                      <NavigationV2.Container
                        tray={true}
                        vertical={true}
                        className={"menu-content"}
                      >
                        <div className={"menu-content-header"}>
                          <NavigationV2.Logo
                            title={<Header>{fishermanBusiness.name}</Header>}
                            src={fishermanBusiness.gatsbyLogo.childImageSharp}
                          />
                          <NavigationV2.Toggle />
                        </div>
                        <NavigationV2.Menu>
                          <NavigationV2.Links
                            links={joinParentWithChildPages({
                              pages: filterRegularLinks({
                                links: allFishermanBusinessWebsitePage.nodes,
                              }),
                              generatedPages: allSitePage.nodes,
                            })}
                          />
                        </NavigationV2.Menu>
                        <NavigationV2.Menu>
                          <NavigationV2.Links
                            links={filterButtonLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            })}
                          />
                        </NavigationV2.Menu>
                      </NavigationV2.Container>
                    </React.Fragment>
                  }
                  id={"navigation"}
                />
              </ScrollElement>
            </React.Fragment>
            <RecaptchaProvider>
              <main className={"page-container"}>{children}</main>
              <footer>
                <Grid stackable={true} verticalAlign={"middle"} columns={3}>
                  <Grid.Row className={"main-row"}>
                    <Grid.Column
                      className={"main-column"}
                      width={4}
                      textAlign={"left"}
                      only={"mobile"}
                    >
                      <Image
                        src={fishermanBusiness.gatsbyLogo.childImageSharp}
                      />
                      <SocialMedia
                        socialMediaValues={fishermanBusiness.socialMedia}
                        buttonType={"rect"}
                        groupButtons={false}
                        showLabels={false}
                      />
                      <div />
                    </Grid.Column>
                    <Grid.Column
                      className={"sitemap-column"}
                      width={6}
                      textAlign={"left"}
                    >
                      <SiteMap
                        links={footerRegularPages.nodes}
                        horizontal={false}
                        bulleted={false}
                      />
                    </Grid.Column>
                    <Grid.Column
                      className={"main-column"}
                      width={4}
                      textAlign={"center"}
                      only={"tablet computer"}
                    >
                      <Image
                        src={fishermanBusiness.gatsbyLogo.childImageSharp}
                      />
                      <SocialMedia
                        socialMediaValues={fishermanBusiness.socialMedia}
                        buttonType={"rect"}
                        groupButtons={false}
                        showLabels={false}
                      />
                      <div />
                    </Grid.Column>
                    <Grid.Column
                      width={6}
                      className={"contact-info-column"}
                      textAlign={"right"}
                    >
                      <div className={"subscribe-cta-container"}>
                        <Popup
                          trigger={
                            <Button secondary={true}>
                              <Icon name={"mail outline"} />
                              <span>Join Our Mailing List</span>
                            </Button>
                          }
                          className={"subscribe-popup-container"}
                          on={"click"}
                          basic={true}
                        >
                          <div className={"subscribe-popup-content"}>
                            <Segment textAlign={"center"} basic={true}>
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <Subscribe
                                    header={"Join Our Mailing List"}
                                    placeholder={"Enter your email address"}
                                    centered={true}
                                    businessId={fishermanBusiness._id}
                                    businessType={fishermanBusiness.type}
                                    onSubmit={createContact}
                                  />
                                }
                                hookToPropsMap={{
                                  locationId: "activeLocation.id",
                                }}
                                hookParams={[LocationsContext]}
                              />
                            </Segment>
                          </div>
                        </Popup>
                      </div>
                      <div className={"address-info-details"}>
                        <div className={"address-info-header footer-header"}>
                          <strong>Address</strong>
                        </div>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <Location
                              inline={true}
                              businessName={fishermanBusiness.name}
                              showBusinessName={false}
                            />
                          }
                          hookToPropsMap={{
                            "address.street": "activeLocation.street",
                            "address.city": "activeLocation.city",
                            "address.state": "activeLocation.state",
                            "address.zip": "activeLocation.zip",
                          }}
                          requiredProps={[["address"]]}
                          hookParams={[LocationsContext]}
                        />
                      </div>
                      <div className={"contact-info-details"}>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <div>
                              <strong>Contact</strong>
                            </div>
                          }
                          hookToPropsMap={{
                            phone: "activeLocation.phoneNumber",
                            email: "activeLocation.email",
                          }}
                          requiredProps={[["phone", "email"]]}
                          hookParams={[LocationsContext]}
                        />
                        <HookComponentProvider
                          hook={useContext}
                          component={Phone}
                          hookToPropsMap={{
                            phone: "activeLocation.phoneNumber",
                          }}
                          hookParams={[LocationsContext]}
                          onlyRenderOnClientSide={true}
                        />
                        <HookComponentProvider
                          hook={useContext}
                          component={Email}
                          hookToPropsMap={{ email: "activeLocation.email" }}
                          hookParams={[LocationsContext]}
                          onlyRenderOnClientSide={true}
                        />
                      </div>
                      <div className={"contact-info-hours"}>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <HoursOfOperation
                              displayOption={"grouped"}
                              displayOpenClosedStatus={true}
                              enhancedLabels={{
                                openingSoon: "Opening soon",
                                open: "Open now",
                              }}
                              header={
                                <div className={"hours-header footer-header"}>
                                  <strong>Lobby and Spa Concierge Hours</strong>
                                </div>
                              }
                            />
                          }
                          hookToPropsMap={{
                            hours: "activeLocation.hours",
                            timezone: "activeLocation.timezone",
                          }}
                          hookParams={[LocationsContext]}
                          onlyRenderOnClientSide={true}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <CopyrightLabel
                  company={fishermanBusiness.name}
                  phrase={"All Rights Reserved"}
                  privacyPolicyLink={
                    <OutboundLink
                      to={
                        "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                      }
                      label={"Privacy Policy"}
                      eventLabel={"copyright-privacy-policy"}
                    />
                  }
                  brand={fishermanBusiness.brandIdentity}
                />
              </footer>
            </RecaptchaProvider>
            <PageTransition />
          </div>
          <FishermanBanner brand={fishermanBusiness.brandIdentity} />
        </React.Fragment>
      );
    }}
  />
);
